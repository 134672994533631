<template>
    <SubNavigation @subNavigationToggle="onSubNavigationToggle">
        <template v-slot:content><DashboardList /></template>
        <DashboardsModule :mode="mode" ref="dashboardsModule" />
    </SubNavigation>
</template>

<script>
import SubNavigation from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/Layout/SubNavigation.vue";
import animateXtoY from "@feedbackcompany/feedback-company-vue-components/src/helpers/animationHelper";
import { mapState } from "vuex";
import DashboardsModule from "@/components/Evaluation/DashboardsModule.vue";
import DashboardList from "@/components/Dashboards/DashboardsList.vue";
import { EventBus } from "@/helpers/evaluation/event-bus";
import clearDashboardsEventListeners from "@/helpers/evaluation/clearDashboardsEventListeners";

export default {
    name: "DashboardsBridge",
    components: {
        DashboardList,
        SubNavigation,
        DashboardsModule,
    },
    data() {
        return {
            mode: null,
        };
    },
    computed: {
        ...mapState({
            dashboards: (state) => state.AccountStore.dashboards,
        }),
        activeDashboardView() {
            return this.$store.state.DashboardStore.activeDashboardView;
        },
    },
    methods: {
        onSubNavigationToggle(state) {
            this.$store.commit("DashboardStore/setDesktopSubNavigationClosed", state.closed);
            EventBus.$emit("redrawAllCharts");
        },
        setMode(dashboardType) {
            if (dashboardType === "DASHBOARD") this.mode = "My Dashboards";
            if (dashboardType === "RESPONSE_OVERVIEW") this.mode = "Communications";
            if (dashboardType === "RESULTS") this.mode = "Results";
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (to.name === "DashboardsBase") {
                // The act clicking Dashboards in main menu should open sub menu on mobile
                animateXtoY({
                    x: 0,
                    y: 1,
                    time: 160,
                    executable: ({ setTo }) => {
                        vm.$store.commit("AccountStore/subNavigationTrayOpenPercentage", {
                            openPercentage: setTo,
                        });
                    },
                });

                // stay on already selected dashboard if we select Dashboard menu item again
                if (vm.activeDashboardView?.id && from.name !== "ResponseOverview") {
                    const activeDashboard = vm.dashboards.find(
                        (dashboard) => dashboard.id == vm.activeDashboardView.id
                    );
                    vm.setMode(activeDashboard.type);
                    vm.$router.push(
                        `/dashboards/${activeDashboard.id}?type=${activeDashboard.type}`
                    );
                } else {
                    // when current route is just /dashboards we want to default to the first one in the list.
                    // @todo: handle case when there are no dashboards
                    vm.setMode(vm.dashboards[0].type);
                    vm.$router.push(
                        `/dashboards/${vm.dashboards[0]?.id}?type=${vm.dashboards[0].type}`
                    );
                }
            }
        });
    },
    beforeRouteUpdate(to, from, next) {
        this.setMode(to.query.type);
        // reloading dashboard state on route change
        this.$refs.dashboardsModule.getDashboards();
        next();
    },
    beforeRouteLeave(to, from, next) {
        // This workaround excludes Dashboards specific stylesheets from other pages
        // by refreshing the page entirely when navigating to another page.
        if (["DashboardsBase", "Dashboards", "ResponseOverview"].includes(to.name)) next();
        else window.location = to.path;
    },
    beforeDestroy() {
        clearDashboardsEventListeners();
    },
};
</script>
